'use client';
import { useEffect, useState } from 'react';
import { ISbEventPayload } from '@storyblok/js';
import { getComponent } from 'app/GetComponent';

export const LiveEditingComponent = ({ component, props }: { component: string, props: any }) => {
    const [content, setContent] = useState(props);

    const Component = getComponent(component)

    useEffect(() => {
        const initStoryblokBridge = () => {
            const setupBridge = () => {
                const bridge = new window.StoryblokBridge();

                bridge.on('input', (event: ISbEventPayload) => {
                    setContent(event.story.content);
                });
            };

            if (window.StoryblokBridge) {
                setupBridge();
            } else {
                window.storyblokRegisterEvent(setupBridge);
            }
        };

        initStoryblokBridge();
    }, []);

    return <Component blok={content} />;
};

export default LiveEditingComponent;
